<template>
  <v-app id="inspire">
    <div  >
      <div class="filter-informe-eventos">
        <div class="container-title-informe">
          <h3><i class="fas fa-chart-line pr-2"></i>Generar informe Detalle Ventas</h3>
        </div>
        <div class="col-12 col-md-8">
          <v-select
            v-model="evento"
            item-value="id"
            item-text="nombre"
            :items="$store.state.informes.eventosInforme"
            label="Evento"
          ></v-select>
        </div>
        <div class="col-12 col-md-4 d-flex flex-wrap align-items-center">
          <button class="btn btn-primary" :disabled="!(evento != null)" @click="getInformeDeEventos()">Generar</button>
        </div>
      </div>


      <div  v-if="$store.state.informes.tableEventoInforme"  >
      <template >
	
            
          <div class="card">
            <div class="header">
                             <h2><strong>Informe Detalle </strong> Ventas Individuales</h2>
             </div>
             <div class="body">
              <div   class="table-responsive" style="height: 400px;"> 
              <table class="table">
                <thead>
                    <tr>
                      <th style="font-size: 11px;" v-if="esRolPermitido">EVENTO_ID</th>
                      <th style="font-size: 11px; width: 120px;">EVENTO</th>
                      <th style="font-size: 11px;" v-if="esRolPermitido">BOLETA_ID</th>
                      <th style="font-size: 11px;">PRECIO_VENTA</th>
                      <th style="font-size: 11px;">PRECIO_SERVICIO</th>
                      <th style="font-size: 11px;" v-if="esRolPermitido">ID_TRIBUNA</th>
                      <th style="font-size: 11px;">NOMBRE_TRIBUNA</th>
                      <th style="font-size: 11px;" v-if="esRolPermitido">ID_LOCALIDAD</th>
                      <th style="font-size: 11px;">LOCALIDAD</th>
                      <th style="font-size: 11px;" v-if="esRolPermitido">ID_FILA</th>
                      <th style="font-size: 11px;">NOMBRE_FILA</th>
                      <th style="font-size: 11px;" v-if="esRolPermitido">PUESTO_ID</th>
                      <th style="font-size: 11px;">NUMERO_PUESTO</th>
                      <th style="font-size: 11px;">PRECIO_VENTA_FINAL</th>
                      <th style="font-size: 11px;">PRECIO_SERVICIO_FINAL</th>
                      <th style="font-size: 11px;">TIPO_VENTA</th>
                      <th style="font-size: 11px;">NOMBRE_CLIENTE</th>
                      <th style="font-size: 11px;">CEDULA_CLIENTE</th>
                      <th style="font-size: 11px;">EMAIL_CLIENTE</th>
                      <th style="font-size: 11px;">TELEFONO_CLIENTE</th>
                      <th style="font-size: 11px;">PUNTO_VENTA</th>
                      <th style="font-size: 11px;">RESPONSABLE</th>
                      <th style="font-size: 11px;">FECHA_VENTA</th>
                      <th style="font-size: 11px;">ESTADO_BOLETA</th>
                    </tr>
                </thead>
                
 






                  <tr     v-for="item in $store.state.informes.tableEventoInforme.ventasIndividuales" >
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_evento }}  </td>
                    <td style="font-size: 10px;" >    {{ item.evento }}  </td>
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_boleta_evento }}  </td>
                    <td style="font-size: 10px;">    {{ item.precio_venta }}  </td>
                    <td style="font-size: 10px;">    {{ item.precio_servicio }}  </td>
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_tribuna }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_tribuna }}  </td>
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_localidad }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_localidad }}  </td>
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_fila }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_fila }}  </td>
                    <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_puesto }}  </td>
                    <td style="font-size: 10px;">    {{ item.numero_puesto }}  </td>
                    <td style="font-size: 10px;">    {{ item.precio_venta_final }}  </td>
                    <td style="font-size: 10px;">    {{ item.precio_servicio_final }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_tipo_venta }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_cliente }} {{ item.apellidos_cliente }}   </td> 
                    <td style="font-size: 10px;">    {{ item.identificacion_cliente }}  </td>
                    <td style="font-size: 10px;">    {{ item.email_cliente }}  </td>
                    <td style="font-size: 10px;">    {{ item.telefono_cliente }}  </td>
                    <td style="font-size: 10px;">    {{ item.punto_venta }}  </td>
                    <td style="font-size: 10px;">    {{ item.responsable }}  </td>
                    <td style="font-size: 10px;">    {{ item.fecha_venta }}  </td>
                    <td style="font-size: 10px;">    {{ item.nombre_boleta_estado }}  </td>
                  </tr>


              </table>
            
            </div>
          </div>
        </div> 
          <div class="card">
            <div class="header">
                              <h2><strong>Informe Detalle </strong> Ventas Palcos</h2>
             </div>
             <div class="body">
              <div   class="table-responsive" style="height: 400px;"> 
              <table class="table">
              <thead>
                  <tr>
                    <th style="font-size: 11px;" v-if="esRolPermitido">EVENTO_ID</th>
                    <th style="font-size: 11px; width: 120px;">EVENTO</th>
                    <th style="font-size: 11px;" v-if="esRolPermitido">PALCO_EVENTO_ID</th>
                    <th style="font-size: 11px;">PRECIO_VENTA</th>
                    <th style="font-size: 11px;">PRECIO_SERVICIO</th>
                    <th style="font-size: 11px;" v-if="esRolPermitido">ID_TRIBUNA</th>
                    <th style="font-size: 11px;">NOMBRE_TRIBUNA</th>
                    <th style="font-size: 11px;" v-if="esRolPermitido">ID_LOCALIDAD</th>
                    <th style="font-size: 11px;">LOCALIDAD</th>
                    <th style="font-size: 11px;" v-if="esRolPermitido">ID_FILA</th>
                    <th style="font-size: 11px;">NOMBRE_FILA</th>
                    <th style="font-size: 11px;" v-if="esRolPermitido">PUESTO_ID</th>
                    <th style="font-size: 11px;">NUMERO_PUESTO</th>
                    <th style="font-size: 11px;">PRECIO_VENTA_FINAL</th>
                    <th style="font-size: 11px;">PRECIO_SERVICIO_FINAL</th>
                    <th style="font-size: 11px;">TIPO_VENTA</th>
                    <th style="font-size: 11px;">NOMBRE_CLIENTE</th>
                    <th style="font-size: 11px;">CEDULA_CLIENTE</th>
                    <th style="font-size: 11px;">EMAIL_CLIENTE</th>
                    <th style="font-size: 11px;">TELEFONO_CLIENTE</th>
                    <th style="font-size: 11px;">PUNTO_VENTA</th>
                    <th style="font-size: 11px;">RESPONSABLE</th>
                    <th style="font-size: 11px;">FECHA_VENTA</th>
                    <th style="font-size: 11px;">ESTADO_BOLETA</th>
                  </tr>
              </thead>

              																								

        <tr     v-for="item in $store.state.informes.tableEventoInforme.ventaspalcos" >
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_evento }}  </td>
                <td style="font-size: 10px;">    {{ item.evento }}  </td>
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_palco_evento }}  </td>
                <td style="font-size: 10px;">    {{ item.precio_venta }}  </td>
                <td style="font-size: 10px;">    {{ item.precio_servicio }}  </td>
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_tribuna }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_tribuna }}  </td>
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_localidad }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_localidad }}  </td>
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_fila }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_fila }}  </td>
                <td style="font-size: 10px;" v-if="esRolPermitido">    {{ item.id_puesto }}  </td>
                <td style="font-size: 10px;">    {{ item.numero_puesto }}  </td>
                <td style="font-size: 10px;">    {{ item.precio_venta_final }}  </td>
                <td style="font-size: 10px;">    {{ item.precio_servicio_final }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_tipo_venta }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_cliente }} {{ item.apellidos_cliente }}  </td>
                <td style="font-size: 10px;">    {{ item.identificacion_cliente }}  </td>
                <td style="font-size: 10px;">    {{ item.email_cliente }}  </td>
                <td style="font-size: 10px;">    {{ item.telefono_cliente }}  </td>
                <td style="font-size: 10px;">    {{ item.punto_venta }}  </td>
                <td style="font-size: 10px;">    {{ item.responsable }}  </td>
                <td style="font-size: 10px;">    {{ item.fecha_venta }}  </td>
                <td style="font-size: 10px;">    {{ item.nombre_boleta_estado }}  </td> 
              </tr>

            </table>
            </div>
          </div>  
        </div> 
          <div class="card">
            <div class="header">
                              <h2><strong>Informe Detalle </strong> Ventas Palcos Individuales</h2>
             </div>
             <div class="body">
              <div   class="table-responsive" style="height: 400px;"> 
              <table class="table">
   <thead>
      <tr>
         <th style="font-size: 11px;" v-if="esRolPermitido">EVENTO_ID</th>
         <th style="font-size: 11px; width: 120px;">EVENTO</th>
         <th style="font-size: 11px;" v-if="esRolPermitido">PALCO_EVENTO_ID</th>
         <th style="font-size: 11px;">PRECIO_VENTA</th>
         <th style="font-size: 11px;">PRECIO_SERVICIO</th>
         <th style="font-size: 11px;" v-if="esRolPermitido">ID_TRIBUNA</th>
         <th style="font-size: 11px;">NOMBRE_TRIBUNA</th>
         <th style="font-size: 11px;" v-if="esRolPermitido">ID_LOCALIDAD</th>
         <th style="font-size: 11px;">LOCALIDAD</th>
         <th style="font-size: 11px;" v-if="esRolPermitido">ID_FILA</th>
         <th style="font-size: 11px;">NOMBRE_FILA</th>
         <th style="font-size: 11px;" v-if="esRolPermitido">PUESTO_ID</th>
         <th style="font-size: 11px;">NUMERO_PUESTO</th>
         <th style="font-size: 11px;">PRECIO_VENTA_FINAL</th>
         <th style="font-size: 11px;">PRECIO_SERVICIO_FINAL</th>
         <th style="font-size: 11px;">TIPO_VENTA</th>
         <th style="font-size: 11px;">NOMBRE_CLIENTE</th>
         <th style="font-size: 11px;">CEDULA_CLIENTE</th>
         <th style="font-size: 11px;">EMAIL_CLIENTE</th>
         <th style="font-size: 11px;">TELEFONO_CLIENTE</th>
         <th style="font-size: 11px;">PUNTO_VENTA</th>
         <th style="font-size: 11px;">RESPONSABLE</th>
         <th style="font-size: 11px;">FECHA_VENTA</th>
         <th style="font-size: 11px;">ESTADO_BOLETA</th>
      </tr>
   </thead>

   																								

    <tr    v-for="item in $store.state.informes.tableEventoInforme.ventaspalcosindividual" >
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_evento }}  </td>
        <td style="font-size: 10px;">   {{ item.evento }}  </td>
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_palco_evento }}  </td>
        <td style="font-size: 10px;">   {{ item.precio_venta }}  </td>
        <td style="font-size: 10px;">   {{ item.precio_servicio }} </td>
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_tribuna }} </td>
        <td style="font-size: 10px;">   {{ item.nombre_tribuna }} </td>
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_localidad }} </td>
        <td style="font-size: 10px;">   {{ item.nombre_localidad }} </td>
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_fila }} </td>
        <td style="font-size: 10px;">   {{ item.nombre_fila }} </td>
        <td style="font-size: 10px;" v-if="esRolPermitido">   {{ item.id_puesto }} </td>
        <td style="font-size: 10px;">   {{ item.numero_puesto }} </td>
        <td style="font-size: 10px;">   {{ item.precio_venta_final }} </td>
        <td style="font-size: 10px;">   {{ item.precio_servicio_final }} </td>
        <td style="font-size: 10px;">   {{ item.nombre_tipo_venta }} </td>
        <td style="font-size: 10px;">   {{ item.nombre_cliente }} {{ item.apellidos_cliente }} </td>
        <td style="font-size: 10px;">   {{ item.identificacion_cliente }} </td>
        <td style="font-size: 10px;">   {{ item.email_cliente }} </td>
        <td style="font-size: 10px;">   {{ item.telefono_cliente }} </td>
        <td style="font-size: 10px;">   {{ item.punto_venta }} </td>
        <td style="font-size: 10px;">   {{ item.responsable }} </td>
        <td style="font-size: 10px;">   {{ item.fecha_venta }}  </td>
        <td style="font-size: 10px;">   {{ item.nombre_boleta_estado }}  </td>
      </tr>


</table>
</div> 
            </div>
          </div>

          <div>
    <button class="btn btn-primary" @click="exportarExcel">Exportar a Excel</button>
  </div>
        </template>
       
      </div>
      
    </div>
    
  </v-app>
</template>

<script>  
import numeral from 'numeral';
import ExcelJS from 'exceljs';

export default { created(){
           document.title = 'Dashboard - TicketShop';
           $(document).ready(function(){
            pagesIndex();
            jqueryKnob();
            sparkline();
            carousel();
            });
        },
  data() {
    return {
      evento: null,
      tingreso:0,
      tporcetaje:0,
      tvendido:0, 
      rol:0,
      array1: [/* primer array */],
      array2: [/* segundo array */],
      array3: [/* tercer array */]
     }
  },
  computed: {
    listadoLocalidades(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).localidades : []
    },
    listadoPuntosVenta(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).puntos_venta : []
    },
    esRolPermitido() {
      const rolesPermitidos = [1, 2,4,5,6,7,8,9,10];
      return rolesPermitidos.includes(this.rol);
    }
  },
  mounted(){
    this.$store.state.informes.tableEventoInforme = null; 
    this.$store.state.informes.taforo = null; 
    this.$store.state.informes.tboletas = null; 
    this.$store.state.informes.tporcentaje=null;
    this.$store.state.informes.aforohabilitado=null;
    this.$store.dispatch('informes/getEventos');
    document.title = 'Informe Aforo evento - TicketShop';
  },
  methods:{
    exportarExcel() {
      const workbook = new ExcelJS.Workbook();

      // Hoja 1
      const worksheet1 = workbook.addWorksheet('Individuales');
        if (this.$store.state.informes.tableEventoInforme.ventasIndividuales.length>0 ) {
      this.exportarArray(worksheet1, this.$store.state.informes.tableEventoInforme.ventasIndividuales); }
 
      // Hoja 2

      const worksheet2 = workbook.addWorksheet('Palcos');
      if (this.$store.state.informes.tableEventoInforme.ventaspalcos.length>0  ) {
      this.exportarArray(worksheet2, this.$store.state.informes.tableEventoInforme.ventaspalcos); }

      // Hoja 3
      const worksheet3 = workbook.addWorksheet('Palcos Individuales');
      if (this.$store.state.informes.tableEventoInforme.ventaspalcosindividual.length>0  ) {
      this.exportarArray(worksheet3, this.$store.state.informes.tableEventoInforme.ventaspalcosindividual); }

      // Guardar el archivo
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'archivo.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    exportarArray(worksheet, array) {
      // Encabezado de columna
      const encabezados = Object.keys(array[0]);

      // Agregar encabezados a la hoja
      worksheet.addRow(encabezados);

      // Datos del array
      array.forEach((item) => {
        const fila = encabezados.map((campo) => item[campo]);
        worksheet.addRow(fila);
      });
    },

    formatoNumero(numero) {
      return numeral(numero).format('0,0');
    },
    async getInformeDeEventos(){
      if(this.evento){
        this.tingreso=0;
        this.tporcetaje=0;
        this. tvendido=0;
        await this.$store.dispatch('informes/getInformesVentasEvento', {
          id_evento: this.evento,
        });
       // tableEventoInforme
       this.rol=this.$store.state.informes.tableEventoInforme.rol ; 
      }
    }
  }
}
</script>

 


<style>
  .filter-informe-eventos{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgb(219, 219, 219);
  }
  .filter-informe-eventos .v-menu__content{
    top: 35px !important;
    left: 0 !important;
  }
  .container-title-informe{
    width: 100%;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 2rem;
  }
  .v-date-picker-table{
    height: 100% !important;
  }
  .v-table__overflow{
    max-height: none !important;
    overflow-y: hidden !important;
  }
  .v-datatable thead tr th:first-child{
    min-width: 300px;
  }
</style>